<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-20 17:40:05
 * @LastEditors: wenwen
 * @LastEditTime: 2024-05-21 14:58:28
-->
<template>
  <el-dialog
    :visible.sync="editFileShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    title="编辑文件"
    width="500px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForms"
      label-width="100px"
      @keyup.enter.native="submitHandle()"
    >
      <el-form-item label="所属分组" prop="groupId">
        <GlobalTreeSelect
          style="width: 320px"
          v-model="dataForm.groupId"
          filterable
          :nodeKey="nodeKey"
          :treeProps="defaultProps"
          :data="treeData"

         >
        </GlobalTreeSelect>
      </el-form-item>
      <el-form-item label="文件名称" prop="fileName">
        <el-input style="width: 320px" v-model="dataForm.fileName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="支持下载" >
        <el-switch v-model="dataForm.downloadFlag"> </el-switch>
      </el-form-item>
      <el-form-item label="文件" >
        <el-upload
          name="files"
          :headers="uploadHeader"
          :action="uploadAction"
          :on-success="_handleSuccess"
          :on-change="onChange"
          :limit="1"
          :file-list="fileLists"
          accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .doc,.docx, .xls, .xlsx, .ppt, .pdf, .mp4,.pptx"
          >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { editListApi } from '@/api/knowledge'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { getCookie } from '@/utils/util'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: {
    GlobalTreeSelect
  },
  props: {
    editFileShow: {
      type: Boolean,
      default: false
    },
    currentFile: {
      type: Object,
      default: () => {}
    },
    treeData: {
      type: Array,
      default: () => []
    },
    defaultProps: {
      type: Object,
      default: () => {}
    },
    nodeKey: {
      type: String,
      default: 'id'
    }
  },
  data () {
    return {
      uploadHeader: {
        token: getCookie('token')
      },
      uploadAction:
        process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload/big/file?toPdfFlag=True', // 上传附件 链接
      dataForm: {
        groupId: '',
        fileName: '',
        downloadFlag: ''
      },
      dataRule: {
        groupId: [
          {
            required: true,
            message: '请输入所属分组',
            trigger: 'blur'
          }
        ],
        fileName: [
          {
            required: true,
            message: '请输入文件名称',
            trigger: 'blur'
          }
        ]

      },
      fileLists: []
    }
  },
  created () {
    this.dataForm = this.currentFile
    if (this.currentFile.downloadFlag == 1) {
      this.dataForm.downloadFlag = true
    } else {
      this.dataForm.downloadFlag = false
    }
  },
  methods: {
    cancel () {
      // 取消
      this.$parent.getQuery()
      this.$parent.editFileShow = false
    },
    submitHandle () {
      this.$refs.dataForms.validate((valid) => {
        if (valid) {
          let downloadFlag = null
          if (this.dataForm.downloadFlag) {
            downloadFlag = 1
          } else {
            downloadFlag = 0
          }
          const fileList = []
          this.fileLists.forEach(item => {
            if (item.response) {
              fileList.push({
                fileType: this.matchType(item.name),
                size: item.size,
                fileDuration: item.response.data && item.response.data[0].fileDuration,
                sourceFileUrl: item.response.data[0].url,
                percentage: item.percentage,
                fileSuffix: item.response.data[0].url.substring(item.response.data[0].url.lastIndexOf('.') + 1)
              })
            }
          })
          editListApi({
            ...this.currentFile,
            ...this.dataForm,
            downloadFlag,
            ...fileList[0]
          }).then(res => {
            if (res.code === 200) {
              this.$parent.getQuery()
              this.$parent.editFileShow = false
            }
          })
        }
      })
    },
    onChange (file, fileList) {
      if (this.fileLists.length > 1) {
        this.$message.info('只能上传一个文件！')
        return false
      }
    },
    _handleSuccess (response, file, fileList) {
      if (response.code == 200) {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.fileLists = arr
        this.dataForm.fileName = arr[0].name
      } else {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.fileLists = arr
        this.dataForm.fileName = arr[0].name
        this.$message.error(response.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-upload--text {
    width: auto;
    height: auto;
    border: none;
}
</style>
